export default theme => ({

    logo: {
        width: '100%',
    },

    loginBlock:{
        padding: '20% 10vw 10vw',
        margin: '0 auto',
    },

    formRow:{
        margin: '0 auto 10px',

    },
    formRowBtn:{
        margin: '10px auto 10px',
        width: '90%',
        '@media(min-width: 400px)': {
            width: '70%',
        },
        '@media(min-width: 500px)': {
            width: '65%',
        },
        '@media(min-width: 600px)': {
            width: '60%',
        },
        '@media(min-width: 700px)': {
            width: '50%',
        },
    },
    pageTitle:{
        marginTop: theme.spacing(2),
        color: theme.palette.textColor.gray,
        fontSize: 18,
        fontWeight: 550,
    },
    pageSubtitle:{
        color: theme.palette.textColor.gray,
        fontSize: 12,
        marginTop: 0,
        marginBottom: 20,
    },
    textField:{
        width: '100%',
    },

    iconColor: {
        color: 'grey',
    },

    loginBtn:{
        marginTop: 30,
        borderRadius: 4,
        textTransform: 'Capitalize',
        fontWeight: 'bold',
        padding: '10px 25px',
        width: '100%'
    },
})
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 30,
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
    title: {
        width: 600
    },
    body: {
        width: 600,
        marginTop: 10
    },
    button: {
        width: 600,
        marginTop: 10,
        textTransform:'capitalize'
    }
}))


export default useStyles;
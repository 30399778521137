import React from 'react';

// 
import { useParams } from 'react-router-dom';

//material-ui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

// komponen halaman pengguna
import Tabel from './tabel';
import Soal from './soal';

// styles
import useStyles from './styles';

function Manage(props) {

    const { location, history, } = props;
    const params = useParams();

    const classes = useStyles();
    const handleChangeTab = (event, value) => {

        history.push(value);
    }

    return (
        <Paper square>
            <Tabs
                centered
                variant="fullWidth"
                value={location.pathname}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTab}
            >
                <Tab label="Tryout" value="/tryout/published" />
                <Tab label="Kategori Soal" value="/tryout/kategori" />
                <Tab label="Soal" value="/tryout/soal" />
            </Tabs>
            <Divider />
            <div className={classes.tabContent}>
                {
                    params.status === 'soal' ?
                        <Soal />
                        :
                        <Tabel />
                }
            </div>
        </Paper>
    )
}

export default Manage;
import format from 'date-fns/format';
import { id } from 'date-fns/esm/locale'
import parse from 'date-fns/parse';

export const formatDate = (timestamp)=>{
    if(!timestamp){
        return null
    }
    return format(new Date(timestamp), "EEEE, dd MMMM yyyy kk:mm 'WIB'", {locale: id} )
}

export const formatDate1 = (timestamp)=>{
    if(!timestamp){
        return null
    }
    return format(new Date(timestamp), "dd/MM/yyyy kk:mm")
}

export const formatDate2 = (timestamp)=>{
    if(!timestamp){
        return null
    }
    return format(new Date(timestamp), "dd LLL yyyy, kk:mm 'WIB'")
}

export const unixToDate = (unix)=>{
    return format(parse(unix, 'T', new Date()), "dd LLL yyyy, kk:mm 'WIB'", {locale: id})
}

export const unixToTime = (unix) => {
    return format(parse(unix, 'T', new Date()), 'EEEE dd MMMM yyyy', {locale: id})
}
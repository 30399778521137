import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useFirebase } from "../../../components/FirebaseProvider";
import { useCollection } from "react-firebase-hooks/firestore";

import MaterialTable from "@material-table/core";
import AppPageLoading from "../../../components/AppPageLoading";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { formatDate } from "../../../utils/formatter";
import Dialog from "./dialog";
import getTableTitle from "../../../utils/getTableTitle";

function Tabel() {
  const params = useParams();

  const { firestore } = useFirebase();

  const queryNews = firestore
    .collection("transaksi")
    .where("status", "==", params.status);
  const [snapshot, loading] = useCollection(queryNews);
  const [dialog, setDialog] = useState({
    open: false,
    data: [],
  });

  const [table, setTable] = useState({
    columns: [
      { title: getTableTitle("ID"), field: "no_transaksi" },
      {
        title: getTableTitle("Nama"),
        field: "nama",
        render: (rowData) => (
          <>
            {rowData && rowData.nama !== undefined ? (
              <span>{rowData.nama}</span>
            ) : (
              <span>Tidak Ada Nama</span>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Email"),
        field: "email",
        render: (rowData) => (
          <>
            {rowData && rowData.email !== undefined ? (
              <span>{rowData.email}</span>
            ) : (
              <span>Tidak Ada Email</span>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Tanggal"),
        field: "tanggal",
        defaultSort: "desc",
        render: (rowData) => <span>{formatDate(rowData.tanggal)}</span>,
      },
      { title: "Jumlah Coin", field: "jumlah_coin", type: "numeric" },
      { title: "Total Pembayaran", field: "jumlah_transfer" },
    ],
    data: [],
  });

  useEffect(() => {
    if (snapshot) {
      setTable((table) => {
        return {
          ...table,
          data: snapshot.docs.map((doc) => {
            return {
              uid: doc.data().doc_id,
              ...doc.data(),
            };
          }),
        };
      });
    }
  }, [snapshot, params.status]);

  if (loading) {
    return <AppPageLoading />;
  }

  let title = "Transaksi";

  if (params.status === "review") {
    title = "Transaksi Ditinjau";
  }
  if (params.status === "konfirmasi") {
    title = "Transaksi Dalam Proses";
  }
  if (params.status === "sukses") {
    title = "Transaksi Dikonfirmasi";
  }
  if (params.status === "rejected") {
    title = "Transaksi Ditolak";
  }

  return (
    <>
      <MaterialTable
        options={{ thirdSortClick: false }}
        title={title}
        columns={table.columns}
        data={table.data}
        actions={
          params.status === "review"
            ? [
              {
                tooltip: "Detail",
                icon: () => <VisibilityIcon />,
                onClick: (event, data) => {
                  setDialog({
                    open: true,
                    data,
                  });
                },
              },
            ]
            : []
        }
        localization={{
          body: {
            emptyDataSourceMessage: `Tidak Ada ${title}`,
            addTooltip: "Tambah",
            editTooltip: "Ubah",
            deleteTooltip: "Hapus",
            editRow: {
              deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
              cancelTooltip: "Batal",
              saveTooltip: "Ya",
            },
          },
          toolbar: {
            searchTooltip: "Cari",
            searchPlaceholder: "Cari Data",
          },
          header: {
            actions: "Tindakan",
          },
          pagination: {
            labelRowsSelect: "Baris",
            labelDisplayedRows: " {from}-{to} Dari {count} Baris",
            firstTooltip: "Halaman Pertama",
            previousTooltip: "Halaman Sebelumnya",
            nextTooltip: "Halaman Selanjutnya",
            lastTooltip: "Halaman Terakhir",
          },
        }}
      />

      <Dialog
        dialog={dialog}
        handleClose={() => {
          setDialog((dialog) => {
            return {
              ...dialog,
              open: false,
            };
          });
        }}
      />
    </>
  );
}

export default Tabel;

import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Manage from './manage';
import Edit from './edit';
import Tambah from './add';

function news() {
    return (
        <Switch>
            <Route exact path="/tryout/:status" component={Manage} />
            <Route path="/tryout/edit/:soalId" component={Edit} />
            <Route path="/tryout/tambah/:tryoutId" component={Tambah} />
            <Redirect to="/tryout/published" />
        </Switch>
    )
}

export default news;
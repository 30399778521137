import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    rooot: {
        flexGrow: 1,
        height: '100vh',
        backgroundColor: theme.palette.textColor.gray4,
        overflow: 'auto',
    },
    appBody: {
        position: 'relative',
        zIndex: 1,
        width: '90%',
        margin: 'auto',
    },
    settingBody: {
        margin: '0 auto 80px',
        border: 'solid 1px' + theme.palette.textColor.gray2,
        '@media(min-width: 600px)': {
            width: '100%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    historyBack: {
        position: 'absolute',
        left: 0,
        top: -3,
    },
    settingList: {
        padding: '0 !important',
    },
    subHeader: {
        backgroundColor: theme.palette.textColor.gray3,
        textAlign: 'left',
    },
    avatarChange: {
        position: 'relative',
        width: 55,
        height: 55,
        margin: '5px auto',
        marginBottom: 12
    },
    fabProgress: {
        position: 'absolute',
        right: -15,
        bottom: -10,
    },
    change: {
        position: 'absolute',
        right: -15,
        bottom: -10,
        width: 24,
        height: 24,
        backgroundColor: theme.palette.textColor.gray2,
        padding: 0,
    },
    icoCamera: {
        width: 15,
        height: 15,
    },
    avatarImg: {
        margin: 10,
        width: 100,
        height: 60,
    }
}))

export default useStyles;
import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Manage from './manage';

function TransaksiEvent() {
    return (
        <Switch>
            <Route exact path="/transaksi-event/:status" component={Manage} />
            <Redirect to="/transaksi-event/review" />
        </Switch>
    )
}

export default TransaksiEvent;
import React from 'react';

//material-ui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

// komponen halaman pengguna
import Tabel from './tabel';
// styles
import useStyles from './styles';

function Manage (props) {

    const { location, history } = props;
    const classes = useStyles();
    const handleChangeTab = (event, value) => {

        history.push(value);
    }

    return (
        <Paper square>
            <Tabs
                centered
                variant="fullWidth"
                value={location.pathname}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTab}
            >
                <Tab label="MITRA" value="/pencairanfee/mitra" />
                <Tab label="BK" value="/pencairanfee/bk" />
            </Tabs>
            <Divider/>
            <div className={classes.tabContent}>
            <Tabel/>
            </div>
        </Paper>
    )
}

export default Manage;
import React, { useState, useEffect } from 'react';

// material-ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
// Utils
import isURL from 'validator/lib/isURL';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFirebase, FieldValue } from '../../../components/FirebaseProvider';
import PropTypes from 'prop-types';
import useStyles from './styles';
import UploadFoto from './uploadFoto';
import FormHelperText from '@material-ui/core/FormHelperText';

function AddDialog({ history, mode, open, data, uid, handleClose }) {

    const { firestore } = useFirebase();

    const classes = useStyles();

    const [form, setForm] = useState({
        kategori: 'Info',
        judul: '',
        gambar: '',
        redirectUrl: ''
    })

    const [error, setError] = useState({
        kategori: '',
        judul: '',
        gambar: '',
        redirectUrl: ''
    })

    const [sizes] = useState({
        Info: "100x68px",
        Komunitas: "50x50px",
        Pendaftaran: "180x80"
    })


    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);
    useEffect(() => {

        const defaultData = {
            kategori: 'Info',
            judul: '',
            gambar: '',
            redirectUrl: ''
        }

        const defaultError = {
            kategori: '',
            judul: '',
            gambar: '',
            redirectUrl: ''
        }
        if (mode === 'Tambah') {
            setForm(defaultData)
            setError(defaultError)
        } else if (mode === 'Edit') {
            setForm(data)
            setError(defaultError)
        }
    }, [mode, data]);
    const handleChange = e => {
        const { name, value } = e.target;

        setForm(oldForm => ({
            ...oldForm,
            [name]: value
        }))

        setError(oldError => ({
            ...oldError,
            [name]: ''
        }))


    }

    const validate = async () => {
        let newError = {};


        if (!form.kategori) {
            newError.kategori = 'Kategori wajib di isi';

        }

        if (!form.judul) {
            newError.judul = 'Judul wajib di isi';

        }

        if (!form.redirectUrl) {
            newError.redirectUrl = 'Redirect URL wajib di isi';

        } else if (!isURL(form.redirectUrl)) {
            newError.redirectUrl = 'URL tidak valid';
        }

        if (!form.gambar) {
            newError.gambar = 'Gambar wajib di isi';

        }


        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();


        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const esportCol = firestore.collection(`esport`);
                let newUid = form.uid;
                if (!form.uid) {
                    newUid = esportCol.doc().id;
                }
                let newData = {
                    ...form,
                    diperbarui_pada: FieldValue.serverTimestamp()
                };
                if (mode === 'Tambah' || !newData.dibuat_pada) {
                    newData.dibuat_pada = FieldValue.serverTimestamp();
                }
                await esportCol.doc(newUid).set(newData);


                enqueueSnackbar('E-Sport berhasil disimpan!', { variant: "success" })
                //await auth.signInWithEmailAndPassword(form.email, form.password);
                //history.push(`/thankyou`)
                handleClose()
            } catch (e) {
                const newError = {};

                newError.judul = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }

    return <Dialog
        disableBackdropClick={isSubmitting}
        disableEscapeKeyDown={isSubmitting}
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>{mode} E-Sport</DialogTitle>
        <DialogContent dividers>
            <form id="create-esport-form" onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <InputLabel>Kategori</InputLabel>
                    <Select
                        className={classes.marginTextfield}
                        labelId="demo-simple-select-label"
                        id="kategori"
                        name="kategori"
                        fullWidth
                        value={form.kategori}
                        onChange={handleChange}
                        disabled={isSubmitting}
                    >
                        <MenuItem value={'Info'}>Info</MenuItem>
                        <MenuItem value={'Komunitas'}>Komunitas</MenuItem>
                        <MenuItem value={'Pendaftaran'}>Pendaftaran</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    className={classes.marginTextfield}
                    disabled={isSubmitting}
                    id="judul"
                    name="judul"
                    label="Judul"
                    fullWidth
                    autoComplete="off"
                    value={form.judul}
                    onChange={handleChange}
                    helperText={error.judul}
                    error={error.judul ? true : false}
                />

                <TextField
                    disabled={isSubmitting}
                    id="redirectUrl"
                    name="redirectUrl"
                    label="Redirect Url"
                    fullWidth
                    value={form.redirectUrl}
                    onChange={handleChange}
                    helperText={error.redirectUrl}
                    error={error.redirectUrl ? true : false}
                />

                <FormControl fullWidth error={error.redirectUrl ? true : false}>
                    <InputLabel>Gambar</InputLabel>
                    <UploadFoto
                        form={form}
                        onChange={handleChange}
                        uid={uid}
                    />
                    <FormHelperText>{error.gambar || `Ukuran Gambar untuk kategori ${form.kategori} adalah ${sizes[form.kategori]}`}</FormHelperText>
                </FormControl>


            </form>
        </DialogContent>
        <DialogActions>
            <Button
                disabled={isSubmitting}
                onClick={handleClose}
            >Batal</Button>
            <Button
                form="create-esport-form"
                disabled={isSubmitting}
                type="submit"
                color="primary">Simpan</Button>
        </DialogActions>
    </Dialog>

}

AddDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withRouter(AddDialog);

import React, { useState, useEffect } from 'react';

// material ui
import Fab from '@material-ui/core/Fab';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { useFirebase } from '../../../../components/FirebaseProvider';

// styles
import useStyles from './styles';

import { useSnackbar } from 'notistack';
import { useCollection } from 'react-firebase-hooks/firestore';
// page component
import AddDialog from './add';
import AppPageLoading from '../../../../components/AppPageLoading';

import MaterialTable from '@material-table/core';
import TextField from '@material-ui/core/TextField';

import isEmail from 'validator/lib/isEmail';
import getTableTitle from '../../../../utils/getTableTitle';

function Admin() {
    const classes = useStyles();
    const { firestore } = useFirebase();

    const queryAdmin = firestore.collection('admin');

    const [snapshot, loading] = useCollection(queryAdmin)
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [table, setTable] = React.useState({
        columns: [
            { title: getTableTitle('ID Pengguna'), field: 'uid', editable: 'never' },
            { title: getTableTitle('Email'), field: 'email' },
            {
                title: 'Kata Sandi', field: 'password',
                sorting: false,
                render: rowData => <span>******</span>,
                editComponent: props => {
                    return <TextField value={props.value} onChange={event => props.onChange(event.target.value)} type="password" />
                }

            },
            {
                title: getTableTitle('Role'), field: 'role', editable: 'never'
            }
        ],
        data: [
        ],
    });

    useEffect(() => {
        if (snapshot && !snapshot.empty) {
            setTable(table => {
                return {
                    ...table,
                    data: snapshot.docs.map(doc => {
                        return {
                            uid: doc.id,
                            ...doc.data()
                        }
                    })
                }
            })
        } else {
            setTable(table => {
                return {
                    ...table,
                    data: []
                }
            })
        }
    }, [snapshot]);


    const validate = async (newData, oldData) => {
        const newError = {};
        const { email: newEmail, password: newPassword } = newData;
        const { email: oldEmail } = oldData;

        if (!newEmail) {
            newError.email = 'Email wajib di isi';

        } else if (!isEmail(newEmail)) {
            newError.email = 'Email tidak valid';
        } else if (newEmail !== oldEmail) {

            const querySanpshot = await firestore.collection('admin').where('email', '==', newEmail).get();

            if (!querySanpshot.empty) {
                newError.email = 'Email sudah terdaftar sebagai admin';
            }
        }

        if (!newPassword) {
            newError.password = 'Password wajib di isi';
        }


        return newError;
    }

    if (loading) {
        return <AppPageLoading />
    }
    return <>

        <MaterialTable
            options={{ thirdSortClick: false }}
            title="Data Admin"
            columns={table.columns}
            data={table.data}
            editable={{

                onRowUpdate: async (newData, oldData) => {


                    const findErrors = await validate(newData, oldData);
                    const errorMessages = Object.values(findErrors);

                    if (errorMessages.some(m => m !== "")) {
                        errorMessages.forEach((message) => {
                            if (message)
                                enqueueSnackbar(message, { variant: 'error' })
                        })
                        throw new Error(errorMessages[0]);
                    }

                    await firestore.doc(`admin/${oldData.uid}`).set(newData, { merge: true });

                }
                ,
                onRowDelete: async (oldData) => {
                    await firestore.doc(`admin/${oldData.uid}`).delete();
                    await firestore.doc(`roles/${oldData.uid}`).delete()
                }
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Tidak Ada Data',
                    addTooltip: 'Tambah',
                    editTooltip: 'Ubah',
                    deleteTooltip: 'Hapus',
                    editRow: {
                        deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
                        cancelTooltip: 'Batal',
                        saveTooltip: 'Ya'
                    }
                },
                toolbar: {
                    searchTooltip: 'Cari',
                    searchPlaceholder: 'Cari Data'
                },
                header: {
                    actions: 'Tindakan'
                },
                pagination: {
                    labelRowsSelect: 'Baris',
                    labelDisplayedRows: ' {from}-{to} Dari {count} Baris',
                    firstTooltip: 'Halaman Pertama',
                    previousTooltip: 'Halaman Sebelumnya',
                    nextTooltip: 'Halaman Selanjutnya',
                    lastTooltip: 'Halaman Terakhir'
                }
            }}
        />
        <Fab
            className={classes.fab}
            color="primary"
            onClick={(e) => {
                setOpenAddDialog(true);
            }}
        >
            <PersonAddOutlinedIcon />
        </Fab>
        <AddDialog
            open={openAddDialog}
            handleClose={() => {
                setOpenAddDialog(false);
            }}
        />
    </>
}

export default Admin;
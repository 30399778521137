import React, { useEffect, useState } from "react";

import useStyles from "./styles";

import { useParams } from "react-router-dom";

import { firestore } from "../../../components/FirebaseProvider";
import { useCollection } from "react-firebase-hooks/firestore";

import MaterialTable from "@material-table/core";
import AppPageLoading from "../../../components/AppPageLoading";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PublishIcon from "@material-ui/icons/Publish";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";

import { currency } from "../../../utils/formatter-rupiah";
import Dialog from "./dialog";
import IconButton from "@material-ui/core/IconButton";
import getTableTitle from "../../../utils/getTableTitle";
import { formatDate } from "../../../utils/formatter";

function Tabel() {
  const params = useParams();

  const classes = useStyles();

  const queryFee = firestore.collection("pencairan_mitra");
  const queryFeeBk = firestore.collection("pencairan_bk");
  const [mitra, loadingmitra] = useCollection(queryFee);
  const [bk, loadingBk] = useCollection(queryFeeBk);
  const [dialog, setDialog] = useState({
    open: false,
    data: [],
    mode: "",
  });

  const [table, setTable] = useState({
    columns: [
      {
        title: "Tindakan",
        field: "status",
        sorting: false,
        render: (rowData) => (
          <>
            {rowData.status === "success" && !rowData.photoUrl ? (
              <>
                <IconButton size="small" disabled>
                  <VisibilityIcon />
                </IconButton>
                <Tooltip title="Upload Bukti Pencairan">
                  <IconButton
                    onClick={(event) => {
                      setDialog({
                        open: true,
                        data: rowData,
                        mode: "upload",
                      });
                    }}
                    size="small"
                  >
                    <PublishIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Pencairan Berhasil Namun Belum Ada Bukti Pencairan">
                  <IconButton size="small">
                    <DoneIcon color="secondary" />
                  </IconButton>
                </Tooltip>
              </>
            ) : rowData.status === "success" && rowData.photoUrl ? (
              <>
                <Tooltip title="Lihat Transaksi">
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      setDialog({
                        open: true,
                        data: rowData,
                        mode: "show",
                      });
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <IconButton size="small" disabled>
                  <PublishIcon />
                </IconButton>
                <Tooltip title="Pencairan Berhasil">
                  <IconButton size="small">
                    <DoneIcon className={classes.success} />
                  </IconButton>
                </Tooltip>
              </>
            ) : rowData.status === "requested" ? (
              <>
                <Tooltip title="Lihat Transaksi">
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      setDialog({
                        open: true,
                        data: rowData,
                        mode: "review",
                      });
                    }}
                  >
                    <VisibilityIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <IconButton disabled size="small">
                  <PublishIcon />
                </IconButton>
                <IconButton size="small" disabled>
                  <DoneIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton size="small" disabled>
                  <VisibilityIcon />
                </IconButton>
                <IconButton disabled size="small">
                  <PublishIcon />
                </IconButton>
                <Tooltip title="Pencairan Ditolak">
                  <IconButton size="small">
                    <ClearIcon color="error" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Nomor"),
        field: "id",
        render: (rowData) => (
          <>
            <span>{rowData.tableData.id + 1}</span>
          </>
        ),
      },
      { title: getTableTitle("Nama Bank"), field: "bank" },
      { title: getTableTitle("Nomor Rekening"), field: "no_rekening" },
      { title: getTableTitle("Nama Pemilik"), field: "nama_pemilik" },
      {
        title: "Nominal Pencairan",
        field: "total_pencairan",
        render: (rowData) => (
          <>
            <span>{currency(rowData.total_pencairan)}</span>
          </>
        ),
      },
      {
        title: getTableTitle("Dicairkan Pada"),
        field: "created_at",
        render: (rowData) => (
          <>
            <span>{formatDate(rowData?.created_at?.toMillis())}</span>
          </>
        ),
      },
      {
        title: getTableTitle("Ditolak Pada"),
        field: "created_at",
        render: (rowData) => (
          <>
            <span>
              {rowData?.status === "success" || rowData?.status === "requested"
                ? "-"
                : formatDate(rowData?.updated_at?.toMillis())}
            </span>
          </>
        ),
      },
      {
        title: getTableTitle("Status"),
        field: "status",
        render: (rowData) => (
          <>
            {rowData.status === "requested" ? (
              <span>Menunggu Diproses</span>
            ) : rowData.status === "success" ? (
              <span>Pencairan Berhasil</span>
            ) : (
              <span>Pencairan Ditolak</span>
            )}
          </>
        ),
      },
    ],
    data: [],
  });

  const [Table, settable] = useState({
    columns: [
      {
        title: "Tindakan",
        field: "status",
        sorting: false,
        render: (rowData) => (
          <>
            {rowData.status === "success" && !rowData.photoUrl ? (
              <>
                <IconButton size="small" disabled>
                  <VisibilityIcon />
                </IconButton>
                <Tooltip title="Upload Bukti Pencairan">
                  <IconButton
                    onClick={(event) => {
                      setDialog({
                        open: true,
                        data: rowData,
                        mode: "upload",
                      });
                    }}
                    size="small"
                  >
                    <PublishIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Pencairan Berhasil Namun Belum Ada Bukti Pencairan">
                  <IconButton size="small">
                    <DoneIcon color="secondary" />
                  </IconButton>
                </Tooltip>
              </>
            ) : rowData.status === "success" && rowData.photoUrl ? (
              <>
                <Tooltip title="Lihat Transaksi">
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      setDialog({
                        open: true,
                        data: rowData,
                        mode: "show",
                      });
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <IconButton size="small" disabled>
                  <PublishIcon />
                </IconButton>
                <Tooltip title="Pencairan Berhasil">
                  <IconButton size="small">
                    <DoneIcon className={classes.success} />
                  </IconButton>
                </Tooltip>
              </>
            ) : rowData.status === "requested" ? (
              <>
                <Tooltip title="Lihat Transaksi">
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      setDialog({
                        open: true,
                        data: rowData,
                        mode: "review",
                      });
                    }}
                  >
                    <VisibilityIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <IconButton disabled size="small">
                  <PublishIcon />
                </IconButton>
                <IconButton size="small" disabled>
                  <DoneIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton size="small" disabled>
                  <VisibilityIcon />
                </IconButton>
                <IconButton disabled size="small">
                  <PublishIcon />
                </IconButton>
                <Tooltip title="Pencairan Ditolak">
                  <IconButton size="small">
                    <ClearIcon color="error" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Nomor"),
        field: "id",
        render: (rowData) => (
          <>
            <span>{rowData.tableData.id + 1}</span>
          </>
        ),
      },
      { title: getTableTitle("Nama Bank"), field: "bank" },
      { title: getTableTitle("Nomor Rekening"), field: "no_rekening" },
      { title: getTableTitle("Nama Pemilik"), field: "nama_pemilik" },
      {
        title: getTableTitle("Nominal Pencairan"),
        field: "total_pencairan",
        render: (rowData) => (
          <>
            <span>{currency(rowData.total_pencairan)}</span>
          </>
        ),
      },
      {
        title: getTableTitle("Dicairkan Pada"),
        field: "created_at",
        render: (rowData) => (
          <>
            <span>{formatDate(rowData?.created_at?.toMillis())}</span>
          </>
        ),
      },
      {
        title: getTableTitle("Ditolak Pada"),
        field: "created_at",
        render: (rowData) => (
          <>
            <span>
              {rowData?.status === "success" || rowData?.status === "requested"
                ? "-"
                : formatDate(rowData?.updated_at?.toMillis())}
            </span>
          </>
        ),
      },
      {
        title: getTableTitle("Status"),
        field: "status",
        render: (rowData) => (
          <>
            {rowData.status === "requested" ? (
              <span>Menunggu Diproses</span>
            ) : rowData.status === "success" ? (
              <span>Pencairan Berhasil</span>
            ) : (
              <span>Pencairan Ditolak</span>
            )}
          </>
        ),
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (mitra) {
      setTable((table) => {
        return {
          ...table,
          data: mitra.docs.map((doc) => {
            return {
              uid: doc.id,
              ...doc.data(),
            };
          }),
        };
      });
    }
    if (bk) {
      settable((Table) => {
        return {
          ...Table,
          data: bk.docs.map((doc) => {
            return {
              uid: doc.id,
              ...doc.data(),
            };
          }),
        };
      });
    }
  }, [bk, mitra]);

  if (loadingmitra || loadingBk) {
    return <AppPageLoading />;
  }

  let title = "Pencairan Fee BK";

  if (params.status === "mitra") {
    title = "Pencairan Fee Mitra";
  }

  return (
    <>
      <MaterialTable
        options={{ thirdSortClick: false }}
        title={title}
        columns={params.status === "bk" ? Table.columns : table.columns}
        data={params.status === "bk" ? Table.data : table.data}
        localization={{
          body: {
            emptyDataSourceMessage: `Tidak Ada ${title}`,
            addTooltip: "Tambah",
            editTooltip: "Ubah",
            deleteTooltip: "Hapus",
            editRow: {
              deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
              cancelTooltip: "Batal",
              saveTooltip: "Ya",
            },
          },
          toolbar: {
            searchTooltip: "Cari",
            searchPlaceholder: "Cari Data",
          },
          header: {
            actions: "Tindakan",
          },
          pagination: {
            labelRowsSelect: "Baris",
            labelDisplayedRows: " {from}-{to} Dari {count} Baris",
            firstTooltip: "Halaman Pertama",
            previousTooltip: "Halaman Sebelumnya",
            nextTooltip: "Halaman Selanjutnya",
            lastTooltip: "Halaman Terakhir",
          },
        }}
      />
      <Dialog
        dialog={dialog}
        handleClose={() => {
          setDialog((dialog) => {
            return {
              ...dialog,
              open: false,
            };
          });
        }}
      />
    </>
  );
}

export default Tabel;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/index';
import mainLogo from '../../images/logo3.svg';

const TopBar = (props) => {

    const { classes } = props;
    
    return (
        <img
            className={classes.mainLogo}
            src={mainLogo}
            alt=""
            width="130vw"
        />
    )
}

export default withStyles(styles)(TopBar);
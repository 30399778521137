//
import React from "react";

//
import { Grid, Typography } from "@material-ui/core";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

function DownloadTemplate() {
  return (
    <a
      style={{
        width: "100%",
        cursor: "pointer",
        padding: 15,
        backgroundColor: "#30bcec",
        color: "white",
        textDecoration: "none",
      }}
      href="https://firebasestorage.googleapis.com/v0/b/pakbudi-prod.appspot.com/o/template-import-voucher.csv?alt=media&token=1cd357fa-be2f-4c4f-b08c-1274b0ddf60e"
    >
      <Grid container spacing={2}>
        <Grid item>
          <CloudDownloadOutlinedIcon fontSize="small" />
        </Grid>
        <Grid item>
          <Typography variant="body2" align="left">
            {" "}
            Klik Disini Untuk Download Template Impor Data Voucher
          </Typography>
        </Grid>
      </Grid>
    </a>
  );
}

export default DownloadTemplate;

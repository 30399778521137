import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Numberformat } from '../../../components/NumberFormat';
import { firestore, Arr } from '../../../components/FirebaseProvider';
import { useSnackbar } from 'notistack';
import { Typography, Divider } from '@material-ui/core';

export default function DialogCoin({ dialog: { open, data }, handleClose }) {

    const [coin, setCoin] = React.useState('');

    const { enqueueSnackbar } = useSnackbar();
    
    const handleValid = async () => {
        let Koin = null;
        if(coin !== ''){
            Koin = parseInt(coin);
        }
        if(coin === ''){
            Koin = parseInt(data.jumlah_coin);
        }
        await firestore.doc(`koin_siswa/${data.user_id}`).set({
            coin: Arr.increment(Koin)
        }, {merge: true})
        await firestore.doc(`transaksi/${data.uid}`).set({
            status: 'sukses'
        }, {merge: true})
        handleClose()
        enqueueSnackbar('Transaksi Telah Divalidasi', {variant: "success"})
    }

    const handleRejected = async () => {
        await firestore.doc(`transaksi/${data.uid}`).set({
            status: 'rejected'
        }, {merge: true})
        handleClose()
        enqueueSnackbar('Transaksi Tidak Divalidasi', {variant: "error"})
    }

    const handleClickView = () => {
        return window.open(data.photoURL)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold' }} id="alert-dialog-title">{"Review Transaksi Coin"}</DialogTitle>
            <IconButton aria-label="close" style={{
                position: 'absolute',
                right: 8,
                top: 8
            }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>

                <Grid style={{ margin: 'auto' }}>
                    <Grid item>
                        <img onClick={handleClickView} width="100%" src={data.photoURL} alt="Gambar Bukti Transaksi" />
                        <br/>
                    </Grid>
                    <Grid item>
                        <br/>
                        <Typography variant="body2" ><b>Info Data Pembeli</b></Typography>
                        <br/>
                        <Typography variant="caption" >Nama :  {data.nama !== undefined ? data.nama : <i>Tidak Ada Nama</i>}</Typography><br/>
                        <Typography variant="caption" >Username :  {data.username !== undefined ? data.username : <i>Tidak Ada Username</i>}</Typography><br/>
                        <Typography variant="caption" >No.Hp:  {data.no_hp !== undefined && data.no_hp !== '' ? data.no_hp : <i>Tidak Ada No.Hp</i>}</Typography><br/>
                        <Typography variant="caption" >Email:  {data.email !== undefined ? data.email : <i>Tidak Ada Email</i>}</Typography><br/>
                        <br/>
                    </Grid>
                    <Divider />
                    <Grid style={{ marginTop: 30, marginBottom: 30 }}>
                        <TextField
                            name="coin"
                            variant="outlined"
                            fullWidth
                            label="Jumlah Coin"
                            onChange={(e) => setCoin(e.target.value)}
                            defaultValue={data.jumlah_coin}
                            InputProps={{ endAdornment: (<InputAdornment position="end">Coin</InputAdornment>), inputComponent: Numberformat }}
                            color="primary"
                        />
                    </Grid>
                    <DialogActions style={{ padding: 0, marginBottom: 20 }}>
                        <Button onClick={handleRejected} fullWidth size="large" variant="contained" style={{ backgroundColor: '#c0392b', color: 'white' }}>
                            Invalid
                        </Button>
                        <Button onClick={handleValid} fullWidth size="large" variant="contained" color="primary">
                            Valid
                        </Button>
                    </DialogActions>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Manage from './manage';

function voucher() {
    return (
        <Switch>
            <Route exact path="/voucher/:category" component={Manage} />
            <Redirect to="/voucher/all" />
        </Switch>
    )
}

export default voucher;
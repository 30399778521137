import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({

    pageTitle: {
        color: '#5e5e5e',
        marginBottom: 15,
            textAlign: 'left',
    }

}))

export default useStyles;
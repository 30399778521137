import React, { useState, useEffect } from "react";

// MUI
import Fab from "@material-ui/core/Fab";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import Avatar from "@material-ui/core/Avatar";
import MaterialTable from '@material-table/core';
import PersonIcon from "@material-ui/icons/Person";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import Tooltip from "@material-ui/core/Tooltip";

// Styles
import useStyles from "./styles";

// Utils
import { useCollection } from "react-firebase-hooks/firestore";
import { useFirebase } from "../../../../components/FirebaseProvider";
// import { useSnackbar } from 'notistack';

// Component
import Dialog from "./dialog";
import AppPageLoading from "../../../../components/AppPageLoading";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EyeIcon from "@material-ui/icons/Visibility";
import StarIcon from "@material-ui/icons/Star";
import { useHistory } from "react-router-dom";
import DialogRating from "./dialogRating";
import getTableTitle from "../../../../utils/getTableTitle";
import { formatDate } from "../../../../utils/formatter";

function Mitra() {
  const classes = useStyles();
  const { firestore } = useFirebase();
  // const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const queryMit = firestore.collection("mitra");
  const [snapshot, loading] = useCollection(queryMit);
  const [dialog, setDialog] = useState({
    mode: "Tambah",
    open: false,
    data: {},
    uid: {},
  });
  const [dialogRating, setDialogRating] = useState({
    open: false,
    uid: {},
  });

  const [table, setTable] = React.useState({
    columns: [
      {
        title: "Profil",
        field: "photoURL",
        sorting: false,
        render: (rowData) => (
          <>
            {rowData.photoURL ? (
              <Avatar src={rowData.photoURL} />
            ) : (
              <Avatar>
                {" "}
                <PersonIcon />{" "}
              </Avatar>
            )}
          </>
        ),
      },
      { title: getTableTitle("Nama"), field: "nama" },
      { title: getTableTitle("Email"), field: "email" },
      { title: getTableTitle("No. Telepon"), field: "no_hp" },
      {
        title: getTableTitle("Tipe Mitra"),
        field: "type",
        render: (rowData) => (
          <>
            {rowData.type === "gurubk" ? (
              <span>Guru Bk</span>
            ) : rowData.type === "mahasiswa" ? (
              <span>Mahasiswa</span>
            ) : (
              <span>Motivator</span>
            )}
          </>
        ),
      },
      { title: getTableTitle("Keterangan"), field: "title" },
      {
        title: getTableTitle("Online"),
        field: "online",
        render: (rowData) => (
          <>
            {rowData.online === true ? (
              <Tooltip title="Mitra Sedang Online" arrow>
                <WifiTetheringIcon style={{ color: "#34cd2b" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Mitra Sedang Offline" arrow>
                <WifiTetheringIcon color="error" />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: getTableTitle('Diperbarui'),
        field: 'diperbarui_pada',
        defaultSort: 'desc',
        render: rowData =>
          <span>
            {formatDate(rowData.diperbarui_pada?.toMillis?.() ?? 0)}
          </span>
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      setTable((table) => {
        return {
          ...table,
          data: snapshot.docs.map((doc) => {
            return {
              uid: doc.id,
              ...doc.data(),
            };
          }),
        };
      });
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [snapshot]);

  if (loading) {
    return <AppPageLoading />;
  }

  return (
    <>
      <MaterialTable
        options={{ thirdSortClick: false }}
        title="Data Mitra"
        columns={table.columns}
        data={table.data}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, data) => {
              setDialog((dialog) => ({
                ...dialog,
                mode: "Edit",
                data,
                uid: data.uid,
                open: true,
              }));
            },
          },
          {
            icon: () => <MonetizationOnIcon />,
            tooltip: "Harga",
            onClick: (event, data) => {
              setDialog((dialog) => ({
                ...dialog,
                mode: "Harga",
                uid: data.uid,
                open: true,
              }));
            },
          },
          {
            icon: () => <EyeIcon />,
            tooltip: "Lihat Transaksi",
            onClick: (event, data) => {
              history.push(`/akun/mitra/detail/${data.uid}`);
            },
          },
          {
            icon: () => <StarIcon />,
            tooltip: "Add Rating",
            onClick: (event, data) => {
              setDialogRating((dr) => ({
                ...dr,
                open: true,
                uid: data?.uid,
              }));
            },
          },
        ]}
        editable={{
          onRowDelete: async (oldData) => {
            await firestore.doc(`mitra/${oldData.uid}`).delete();
            await firestore.doc(`roles/${oldData.uid}`).delete();
            await firestore.doc(`harga_mitra/${oldData.uid}`).delete();
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Tidak Ada Data",
            addTooltip: "Tambah",
            editTooltip: "Ubah",
            deleteTooltip: "Hapus",
            editRow: {
              deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
              cancelTooltip: "Batal",
              saveTooltip: "Ya",
            },
          },
          toolbar: {
            searchTooltip: "Cari",
            searchPlaceholder: "Cari Data",
          },
          header: {
            actions: "Tindakan",
          },
          pagination: {
            labelRowsSelect: "Baris",
            labelDisplayedRows: " {from}-{to} Dari {count} Baris",
            firstTooltip: "Halaman Pertama",
            previousTooltip: "Halaman Sebelumnya",
            nextTooltip: "Halaman Selanjutnya",
            lastTooltip: "Halaman Terakhir",
          },
        }}

      />
      <Fab
        className={classes.fab}
        color="primary"
        onClick={(e) => {
          setDialog({ mode: "Tambah", open: true, data: {} });
        }}
      >
        <PersonAddOutlinedIcon />
      </Fab>
      <Dialog
        dialog={dialog}
        handleClose={() => {
          setDialog({ mode: "Tambah", open: false, data: {} });
        }}
      />
      <DialogRating
        dialog={dialogRating}
        handleClose={() => {
          setDialogRating({
            open: false,
            uid: "",
          });
        }}
      />
    </>
  );
}

export default Mitra;

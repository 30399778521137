import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Manage from './manage';
import Edit from './edit';
import Peserta from './peserta';

function Event() {
    return (
        <Switch>
            <Route exact path="/event/:status" component={Manage} />
            <Route path="/event/edit/:eventId" component={Edit} />
            <Route path="/event/peserta/:eventId" component={Peserta} />
            <Redirect to="/event/published" />
        </Switch>
    )
}

export default Event;
import React from 'react';

//material-ui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

// komponen halaman pengguna
import Tabel from './tabel';
import Upload from './uploadBanner';
// styles
import useStyles from './styles';

function Manage (props) {

    const { location, history, } = props;
    const classes = useStyles();
    const handleChangeTab = (event, value) => {

        history.push(value);
    }

    return (
        <Paper square>
            <Tabs
                centered
                variant="fullWidth"
                value={location.pathname}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTab}
            >
                <Tab label="Diterbitkan" value="/news/published" />
                <Tab label="Draf" value="/news/draft" />
                <Tab label="Sampah" value="/news/trash" />
                <Tab label="Kategori" value="/news/kategori" />
                <Tab label="Banner" value="/news/banner" />
            </Tabs>
            <Divider/>
            <div className={classes.tabContent}>
                {
                    history.location.pathname === '/news/banner' ?
                        <Upload />
                    :
                        <Tabel/>
                }
            </div>
        </Paper>
    )
}

export default Manage;
// React
import React from 'react';

// Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DownloadDialog({ handleClose, open, handleDownloadData, isLoad }) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Unduh Data Hasil Tryout"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin mengunduh seluruh data hasil tryout dari kategori tryout yang anda pilih ? jika ya, silahkan tekan tombol download. Jika tidak, silahkan tekan tombol kembali.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        fullWidth
                        onClick={handleClose}
                        style={{
                            color: '#e62120',
                        }}
                        variant="outlined"
                    >
                        Kembali
                     </Button>
                    <Button
                        fullWidth
                        disabled={isLoad === true}
                        onClick={handleDownloadData}
                        color="primary"
                        autoFocus
                        variant="contained"
                    >
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Manage from './manage';
import DetailMisi from './addDetail';

function Misi() {
    return (
        <Switch>
            <Route exact path="/misi/:status" component={Manage} />
            <Route exact path="/misi/edit/:misiId" component={DetailMisi} />
            <Redirect to="/misi/kelola" />
        </Switch>
    )
}

export default Misi;
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(5)
    },
    marginTextfield: {
        marginBottom: 15,
    },
    pengaturanPengguna: {
        display: 'flex',
        flexDirection: 'column',
        width: 300
    }

}))


export default useStyles;
import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Manage from './manage';

function transaksi() {
    return (
        <Switch>
            <Route exact path="/transaksi/:status" component={Manage} />
            <Redirect to="/transaksi/review" />
        </Switch>
    )
}

export default transaksi;
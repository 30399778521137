import React, { useState } from "react";

import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";

import { useSnackbar } from "notistack";

import { firestore } from "../../../../components/FirebaseProvider";

function DialogRating({ dialog: { open, uid }, handleClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmit, setIsSubmit] = useState(false);

  const [hover, setHover] = React.useState(-1);
  const [value, setValue] = React.useState(-1);
  const [error, setError] = React.useState({
    rating: "",
  });

  const validate = () => {
    const newError = { ...error };

    if (hover === -1) {
      newError.rating = "Rating Wajib Diisi";
    }

    return newError;
  };

  const handleKirim = async (e) => {
    e.preventDefault();

    const findErrors = validate();

    setIsSubmit(true);

    //
    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      await firestore.collection("rating_mitra").add({
        siswa_id: null,
        mitra_id: uid,
        rating: hover,
        comment: "rating dari admin",
        chatId: null,
      });

      enqueueSnackbar("Rating berhasil di tambahkan", { variant: "success" });

      setHover(-1);
      setValue(-1);

      handleClose();
    }

    setIsSubmit(false);
  };

  return (
    <Dialog
      aria-labelledby="rate-modal-title"
      aria-describedby="rate-modal-description"
      open={open}
    >
      <DialogTitle disableTypography id="rate-dialog-title">
        {"Tambahkan Rating Untuk Mitra"}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <div>
            <Rating
              name="hover"
              value={hover}
              onChange={(event, newHover) => {
                setHover(newHover);
                setError({ rating: "" });
              }}
              onChangeActive={(event, newValue) => {
                setValue(newValue);
                setError({ rating: "" });
              }}
            />
            <Typography component="p" color="error" align="center">
              {error.rating}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          fullWidth
          onClick={() => {
            setError({ rating: "" });

            handleClose();
          }}
          disabled={isSubmit}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          onClick={handleKirim}
          disabled={isSubmit}
        >
          Kirim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogRating;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFirebase } from '../../../../components/FirebaseProvider';

// utils
import isEmail from 'validator/lib/isEmail';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useStyles from './styles';

// app components
import SelectProvinsi from '../../../../components/AppSelect/provinsi';
import SelectKota from '../../../../components/AppSelect/kota';
import SelectSekolah from '../../../../components/AppSelect/sekolah';

function AddDialog({ dialog: { mode, open, data }, handleClose }) {

    const { firestore, FieldValue } = useFirebase();

    const classes = useStyles();

    const [form, setForm] = useState({
        nama: '',
        sekolah: {
            nama: '',
            sekolah_id: ''
        },

        provinsi: {
            nama: '',
            provinsi_id: ''
        },
        kota: {
            nama: '',
            kota_id: ''
        },
        email: '',
        password: ''
    })

    const [error, setError] = useState({
        nama: '',
        sekolah: '',
        provinsi: '',
        kota: '',
        email: '',
        password: ''
    })
    useEffect(() => {
        const defaultData = {
            nama: '',

            sekolah: {
                nama: '',
                sekolah_id: ''
            },
            provinsi: {
                nama: '',
                provinsi_id: ''
            },
            kota: {
                nama: '',
                kota_id: ''
            },
            email: '',
            password: ''
        }

        const defaultError = {
            nama: '',
            sekolah: '',
            provinsi: '',
            kota: '',
            email: '',
            password: ''
        }
        if (mode === 'Tambah') {
            setForm(defaultData)
            setError(defaultError)
        } else if (mode === 'Edit') {
            setForm(data)
            setError(defaultError)
        }
    }, [mode, data])



    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setForm(form => ({
            ...form,
            [name]: value
        }))

        setError(error => ({
            ...error,
            [name]: ''
        }))
    }

    const validate = async () => {
        const newError = { ...error };
        const { email, password, nama, sekolah, provinsi, kota } = form;


        if (!email) {
            newError.email = 'Email wajib di isi';

        } else if (!isEmail(email)) {
            newError.email = 'Email tidak valid';
        } else if (data.email !== email) {
            const querySanpshot = await firestore.collection('bk').where('email', '==', email).get();

            if (!querySanpshot.empty) {
                newError.email = 'Email sudah terdaftar sebagai admin';
            }
        }

        if (!password) {
            newError.password = 'Password wajib di isi';
        }

        if (!nama) {
            newError.nama = 'Nama wajib di isi';
        }

        if (!sekolah.sekolah_id) {
            newError.sekolah = 'Sekolah wajib di isi';
        }

        if (!provinsi.provinsi_id) {
            newError.provinsi = 'Provinsi wajib di isi';
        }

        if (!kota.kota_id) {
            newError.kota = 'Provinsi wajib di isi';
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();


        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const bkCol = firestore.collection(`bk`);
                if (mode === 'Tambah') {
                    const newBk = await bkCol.add({

                        ...form,
                        created_at: FieldValue.serverTimestamp(),
                        updated_at: FieldValue.serverTimestamp()
                        
                    });

                    await firestore.doc(`roles/${newBk.id}`).set({
                        akses: 'bk'
                    })
                    enqueueSnackbar('Guru BK berhasil ditambahkan', { variant: "success" })
                } else if (mode === 'Edit') {
                    const { uid, tableData, ...restForm } = form;
                    await bkCol.doc(uid).update({
                        ...restForm, updated_at: FieldValue.serverTimestamp()
                    })
                    enqueueSnackbar('Guru BK berhasil diperbarui', { variant: "success" })
                }
                
                handleClose()
            } catch (e) {
                const newError = {};

                newError.email = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }

    return <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick={isSubmitting}
        disableEscapeKeyDown={isSubmitting}
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>{mode} Guru BK</DialogTitle>
        <DialogContent dividers>
            <form id="create-admin-form" onSubmit={handleSubmit}>

                <TextField
                    className={classes.marginTextfield}
                    disabled={isSubmitting}
                    id="nama"
                    name="nama"
                    label="Nama"

                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    autoComplete="off"
                    value={form.nama}
                    onChange={handleChange}
                    helperText={error.nama}
                    error={error.nama ? true : false}
                />


                <SelectProvinsi
                    className={classes.marginTextfield}
                    value={{ label: form.provinsi.nama, value: form.provinsi.provinsi_id }}
                    onChange={({ value, label }) => {
                        handleChange({
                            target: {
                                value: {
                                    nama: label,
                                    provinsi_id: value
                                }, name: 'provinsi'
                            }
                        })
                        handleChange({
                            target: {
                                value: {
                                    nama: '',
                                    kota_id: ''
                                }, name: 'kota'
                            }
                        })
                        handleChange({
                            target: {
                                value: {
                                    nama: '',
                                    sekolah_id: ''
                                }, name: 'sekolah'
                            }
                        })
                    }}

                    TextFieldProps={{
                        disabled: isSubmitting,
                        helperText: error.provinsi,
                        error: error.provinsi ? true : false
                    }}


                />



                <SelectKota
                    className={classes.marginTextfield}
                    value={{ label: form.kota.nama, value: form.kota.kota_id }}
                    onChange={({ value, label }) => {
                        handleChange({
                            target: {
                                value: {
                                    nama: label,
                                    kota_id: value
                                }, name: 'kota'
                            }
                        })
                        handleChange({
                            target: {
                                value: {
                                    nama: '',
                                    sekolah_id: ''
                                }, name: 'sekolah'
                            }
                        })
                    }}
                    provinsi_id={form.provinsi.provinsi_id}
                    TextFieldProps={{
                        disabled: isSubmitting,
                        helperText: error.kota,
                        error: error.kota ? true : false
                    }}


                />

                <SelectSekolah
                    className={classes.marginTextfield}
                    value={{ label: form.sekolah.nama, value: form.sekolah.sekolah_id }}
                    onChange={({ value, label }) => {


                        handleChange({
                            target: {
                                value: {

                                    nama: label,
                                    sekolah_id: value
                                }, name: 'sekolah'
                            }
                        })

                    }}
                    provinsi_id={form.provinsi.provinsi_id}
                    kota_id={form.kota.kota_id}
                    TextFieldProps={{
                        disabled: isSubmitting,
                        helperText: error.sekolah,
                        error: error.sekolah ? true : false
                    }}


                />
                <TextField
                    className={classes.marginTextfield}
                    disabled={isSubmitting}
                    id="email"
                    name="email"
                    label="Email"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    autoComplete="off"
                    value={form.email}
                    onChange={handleChange}
                    helperText={error.email}
                    error={error.email ? true : false}
                />

                <TextField
                    className={classes.marginTextfield}
                    disabled={isSubmitting}
                    id="password"
                    name="password"
                    label="Password"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="password"
                    autoComplete="new-password"
                    fullWidth
                    value={form.password}
                    onChange={handleChange}
                    helperText={error.password}
                    error={error.password ? true : false}
                />
            </form>
        </DialogContent>
        <DialogActions>
            <Button
                disabled={isSubmitting}
                onClick={handleClose}
            >Batal</Button>
            <Button
                form="create-admin-form"
                disabled={isSubmitting}
                type="submit"
                color="primary">Simpan</Button>
        </DialogActions>
    </Dialog>

}

AddDialog.propTypes = {
    dialog: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withRouter(AddDialog);

import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';;

function NumberFormatRp(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                        name: other.name
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            isNumericString
            prefix="Rp. "
        />
    );
}

export function Numberformat(props){
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                        name: other.name
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            isNumericString
        />
    );
}

Numberformat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
}

NumberFormatRp.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default NumberFormatRp;
import React from 'react';

//material-ui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { Switch, Route, Redirect } from 'react-router-dom';
// komponen halaman pengguna
import Sekolah from './sekolah';
import Jurusan from './jurusan';

function Pengaturan(props) {

    const { location, history } = props;
    const handleChangeTab = (event, value) => {

        history.push(value);
    }

    return (
        <Paper square style={{marginBottom: 50}}>
            <Tabs
                centered
                variant="fullWidth"
                value={location.pathname}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTab}
            >
                <Tab label="Sekolah" value="/data/sekolah" />
                <Tab label="Jurusan Kuliah" value="/data/jurusan-kuliah" />
            </Tabs>
            <Divider/>
            <div>
                <Switch>
                    <Route path="/data/sekolah" component={Sekolah} />
                    <Route path="/data/jurusan-kuliah" component={Jurusan} />
                    <Redirect to="/data/sekolah" />
                </Switch>
            </div>
        </Paper>
    )
}

export default Pengaturan;
import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Manage from './manage';
import Edit from './edit';

function news() {
    return (
        <Switch>
            <Route exact path="/news/:status" component={Manage} />
            <Route path="/news/edit/:newsId" component={Edit} />
            <Redirect to="/news/published" />
        </Switch>
    )
}

export default news;
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

// utils
import isEmail from "validator/lib/isEmail";
import { withRouter } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FieldValue, firestore } from "../../../../components/FirebaseProvider";
import { useDocument } from "react-firebase-hooks/firestore";
import AppPageLoading from "../../../../components/AppPageLoading";
import useStyles from "./styles";
import { Numberformat } from "../../../../components/NumberFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import UploadFoto from "./uploadFoto";
// import { Typography } from '@material-ui/core';

function AddDialog({ dialog: { mode, open, data, uid }, handleClose }) {
  const classes = useStyles();

  const queryHarga = firestore.doc(`harga_mitra/${uid}`);
  const [snapshot, loading] = useDocument(queryHarga);

  const [form, setForm] = useState({
    nama: "",
    email: "",
    password: "",
    no_hp: "",
    online: false,
    photoURL: "",
    title: "",
    type: "",
    harga: "",
    durasi: 5,
    max_active_chat: 3,
    fee: false,
  });

  const [seePassword, setSeePasword] = useState(false);

  const [error, setError] = useState({
    nama: "",
    email: "",
    password: "",
    no_hp: "",
    photoURL: "",
    title: "",
    type: "",
  });
  useEffect(() => {
    const defaultData = {
      nama: "",
      email: "",
      password: "",
      no_hp: "",
      online: false,
      photoURL: "",
      title: "",
      type: "",
      harga: "",
      durasi: 5,
      max_active_chat: 3,
      fee: false,
    };

    const defaultError = {
      nama: "",
      email: "",
      password: "",
      no_hp: "",
      photoURL: "",
      title: "",
      type: "",
    };
    if (mode === "Tambah") {
      setForm(defaultData);
      setError(defaultError);
    } else if (mode === "Edit") {
      setForm({ durasi: 5, max_active_chat: 3, ...data });
      setError(defaultError);
    } else if (mode === "Harga") {
      if (snapshot && !snapshot.empty) {
        setForm({
          ...snapshot.data(),
        });
      }
    }
  }, [mode, data, snapshot]);

  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setError((error) => ({
      ...error,
      [name]: "",
    }));
  };

  const validate = async () => {
    const newError = { ...error };
    const {
      nama,
      email,
      password,
      no_hp,
      title,
      type,
      durasi,
      max_active_chat,
    } = form;

    if (!email) {
      newError.email = "Email Wajib Diisi";
    } else if (!isEmail(email)) {
      newError.email = "Email Tidak Valid";
    } else if (data.email !== email) {
      const emailVal = await firestore
        .collection("mitra")
        .where("email", "==", email)
        .get();
      const emailVal1 = await firestore
        .collection("siswa")
        .where("email", "==", email)
        .get();

      if (!emailVal.empty) {
        newError.email = "Email Sudah Terdaftar";
      }

      if (!emailVal1.empty) {
        newError.email = "Email Sudah Terdaftar Sebagai Siswa";
      }
    }

    if (!password) {
      newError.password = "Password Wajib Diisi";
    } else if (password.length < 9) {
      newError.password = "Password Minimal 9 Karakter";
    }

    if (!nama) {
      newError.nama = "Nama Wajib Diisi";
    }

    if (!no_hp) {
      newError.no_hp = "Nomor Telepon Wajib Diisi";
    }

    if (!title) {
      newError.title = "Keterangan Wajib Diisi";
    }

    if (!type) {
      newError.type = "Tipe Mitra Wajib Dipilih";
    }

    if (!durasi) {
      newError.durasi = "Durasi konsultasi Wajib Diisi";
    }

    if (!max_active_chat) {
      newError.max_active_chat = "Max Active Chat konsultasi Wajib Diisi";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        const mitCol = firestore.collection(`mitra`);
        if (mode === "Tambah") {
          let newUid = form.uid;
          if (!form.uid) {
            newUid = mitCol.doc().id;
          }
          await mitCol.doc(newUid).set(
            {
              nama: form.nama,
              email: form.email,
              password: form.password,
              no_hp: form.no_hp,
              online: false,
              photoURL: form.photoURL,
              title: form.title,
              type: form.type,
              durasi: parseInt(form.durasi),
              max_active_chat: parseInt(form.max_active_chat),
              diperbarui_pada: FieldValue.serverTimestamp(),
            },
            { merge: true }
          );

          if (form.harga !== "") {
            await firestore.doc(`harga_mitra/${newUid}`).set(
              {
                harga: form.harga,
              },
              { merge: true }
            );
          } else {
            await firestore
              .doc(`harga_mitra/${newUid}`)
              .set({}, { merge: true });
          }

          await firestore.doc(`roles/${newUid}`).set(
            {
              akses: "mitra",
            },
            { merge: true }
          );
          enqueueSnackbar("Mitra berhasil ditambahkan", { variant: "success" });
        } else if (mode === "Edit") {
          const { uid, tableData, ...restForm } = form;
          await mitCol.doc(uid).set(
            {
              ...restForm,
              durasi: parseInt(form.durasi),
              max_active_chat: parseInt(form.max_active_chat),
              diperbarui_pada: FieldValue.serverTimestamp(),
            },
            { merge: true }
          );
          enqueueSnackbar("Akun Berhasil Diperbarui", { variant: "success" });
        }
        handleClose();
      } catch (e) {
        const newError = {};

        newError.email = e.message;

        setError(newError);
      }

      setSubmitting(false);
    }
  };

  const handleHarga = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      if (mode === "Harga") {
        const docId = uid;
        if (form.harga !== "") {
          await firestore.doc(`harga_mitra/${docId}`).set(
            {
              harga: form.harga,
            },
            { merge: true }
          );
        } else {
          await firestore.doc(`harga_mitra/${docId}`).set({}, { merge: true });
        }
      }
      handleClose();
    } catch (e) {
      const newError = {};

      newError.email = e.message;
    }

    setSubmitting(false);
  };

  const handleClickShowPassword = () => {
    setSeePasword(!seePassword);
  };

  if (loading) {
    return <AppPageLoading />;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{mode} Akun Mitra</DialogTitle>
      <DialogContent dividers>
        <form
          id="create-admin-form"
          onSubmit={mode === "Harga" ? handleHarga : handleSubmit}
        >
          {mode === "Tambah" && (
            <>
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="nama"
                name="nama"
                label="Nama"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="off"
                value={form.nama}
                onChange={handleChange}
                helperText={error.nama}
                error={error.nama ? true : false}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="email"
                name="email"
                label="Email"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="off"
                value={form.email}
                onChange={handleChange}
                helperText={error.email}
                error={error.email ? true : false}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="password"
                name="password"
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                type={seePassword ? "text" : "password"}
                autoComplete="new-password"
                fullWidth
                value={form.password}
                onChange={handleChange}
                helperText={error.password}
                error={error.password ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {seePassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="no_hp"
                name="no_hp"
                label="Nomor Telepon"
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
                fullWidth
                value={form.no_hp}
                onChange={handleChange}
                helperText={error.no_hp}
                error={error.no_hp ? true : false}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="title"
                name="title"
                label="Keterangan"
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
                fullWidth
                value={form.title}
                onChange={handleChange}
                helperText={error.title}
                error={error.title ? true : false}
              />
              <FormControl style={{ width: "100%" }}>
                <InputLabel>Tipe</InputLabel>
                <Select
                  className={classes.marginTextfield}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="type"
                  fullWidth
                  value={form.type}
                  onChange={handleChange}
                  disabled={isSubmitting}
                >
                  <MenuItem value={"gurubk"}>Guru BK</MenuItem>
                  <MenuItem value={"mahasiswa"}>Mahasiswa</MenuItem>
                  <MenuItem value={"motivator"}>Motivator</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="harga"
                name="harga"
                label="Harga Jasa"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Coin</InputAdornment>
                  ),
                  inputComponent: Numberformat,
                }}
                autoComplete="off"
                fullWidth
                value={form.harga}
                onChange={handleChange}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="durasi"
                name="durasi"
                label="Durasi Konsultasi"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">menit</InputAdornment>
                  ),
                  inputComponent: Numberformat,
                }}
                autoComplete="off"
                fullWidth
                value={form.durasi}
                onChange={handleChange}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="max_active_chat"
                name="max_active_chat"
                label="Max Active Chat"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Chat</InputAdornment>
                  ),
                  inputComponent: Numberformat,
                }}
                autoComplete="off"
                fullWidth
                value={form.max_active_chat}
                onChange={handleChange}
              />
              <UploadFoto profile={form} onChange={handleChange} uid={uid} />
            </>
          )}
          {mode === "Edit" && (
            <>
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="nama"
                name="nama"
                label="Nama"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="off"
                value={form.nama}
                onChange={handleChange}
                helperText={error.nama}
                error={error.nama ? true : false}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="email"
                name="email"
                label="Email"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="off"
                value={form.email}
                onChange={handleChange}
                helperText={error.email}
                error={error.email ? true : false}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="password"
                name="password"
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                type={seePassword ? "text" : "password"}
                autoComplete="new-password"
                fullWidth
                value={form.password}
                onChange={handleChange}
                helperText={error.password}
                error={error.password ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {seePassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="no_hp"
                name="no_hp"
                label="Nomor Telepon"
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
                fullWidth
                value={form.no_hp}
                onChange={handleChange}
                helperText={error.no_hp}
                error={error.no_hp ? true : false}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="title"
                name="title"
                label="Keterangan"
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
                fullWidth
                value={form.title}
                onChange={handleChange}
                helperText={error.title}
                error={error.title ? true : false}
              />
              <FormControl style={{ width: "100%" }}>
                <InputLabel>Tipe</InputLabel>
                <Select
                  className={classes.marginTextfield}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="type"
                  fullWidth
                  value={form.type}
                  onChange={handleChange}
                >
                  <MenuItem value={"gurubk"}>Guru BK</MenuItem>
                  <MenuItem value={"mahasiswa"}>Mahasiswa</MenuItem>
                  <MenuItem value={"motivator"}>Motivator</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ width: "100%" }}>
                <InputLabel>Status Akun</InputLabel>
                <Select
                  className={classes.marginTextfield}
                  labelId="online"
                  id="online"
                  name="online"
                  fullWidth
                  value={form.online}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Online</MenuItem>
                  <MenuItem value={false}>Offline</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="durasi"
                name="durasi"
                label="Durasi Konsultasi"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">menit</InputAdornment>
                  ),
                  inputComponent: Numberformat,
                }}
                autoComplete="off"
                fullWidth
                value={form.durasi}
                onChange={handleChange}
              />
              <TextField
                className={classes.marginTextfield}
                disabled={isSubmitting}
                id="max_active_chat"
                name="max_active_chat"
                label="Max Active Chat"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Chat</InputAdornment>
                  ),
                  inputComponent: Numberformat,
                }}
                autoComplete="off"
                fullWidth
                value={form.max_active_chat}
                onChange={handleChange}
              />
              <UploadFoto profile={form} onChange={handleChange} uid={uid} />
            </>
          )}
          {mode === "Harga" && (
            <TextField
              className={classes.marginTextfield}
              disabled={isSubmitting}
              id="harga"
              name="harga"
              label="Harga Jasa"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Coin</InputAdornment>
                ),
                inputComponent: Numberformat,
              }}
              autoComplete="off"
              fullWidth
              value={form.harga}
              onChange={handleChange}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Batal
        </Button>
        <Button
          form="create-admin-form"
          disabled={isSubmitting}
          type="submit"
          color="primary"
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddDialog.propTypes = {
  dialog: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withRouter(AddDialog);
